import { type AppType } from "next/dist/shared/lib/utils";
import { Config } from "@cleeng/mediastore-sdk";
import Nav from "~/components/nav";
import "~/styles/globals.css";
import styles from "./index.module.css";
import "@adyen/adyen-web/dist/adyen.css";
import "react-loading-skeleton/dist/skeleton.css";
import Script from "next/script";

const MyApp: AppType = ({ Component, pageProps }) => {

  Config.setEnvironment(process.env.NEXT_PUBLIC_CLEENG_ENVIRONMENT ?? 'production');
  Config.setPublisher(process.env.NEXT_PUBLIC_PUBLISHER_ID!);

  return (
    <>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-415Q3XRC80"
        strategy="afterInteractive"
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
      >
        {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_GA_CONFIG}');
            `}
      </Script>
      <Nav />
      <div className={styles.componentContainer}>
        <Component {...pageProps} />
      </div>
    </>);
};

export default MyApp;
